<template>
  <v-container
    id="accountGroups"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="accountGroups"
      :items-per-page="10"
      class="elevation-1"
      item-key="id"
      :search="filterText"
      :loading="loading"
      loading-text="Carregando os grupos..."
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Grupos de contas</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-8"
            label="Filtrar"
            v-model="filterText"
            solo
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            @click="newGroup"
          >
            Novo grupo
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip v-if="item.active"
          color="success"
          dark
        >
          Ativo
        </v-chip>
        <v-chip v-else
          color="error"
          dark
        >
          Inativo
        </v-chip>
      </template>
      <template v-slot:item.modified="{ item }">
        {{ getFormattedDate(item.modified) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          fab
          x-small
          @click="editGroup(item)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          color="error"
          fab
          x-small
          @click="confirmDeleteGroup(item)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Confirmação de exclusão -->
    <v-dialog
      v-model="confirmDelete"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Excluir grupo de conta
        </v-card-title>

        <v-card-text>
          Deseja realmente excluir o grupo {{ selectedGroup.id }} - {{ selectedGroup.account }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete = false"
          >
            Não
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="deleteGroup"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Excluido com sucesso -->
    <v-dialog
      v-model="successDelete"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline">
          Grupo de conta excluído.
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="successDelete = false"
          >
            Ok
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="progressDelete"
      max-width="350"
    >
      <v-card>
        <v-progress-linear
          :indeterminate="true"
          height="30"
          :active="true"
          color="deep-purple darken-4"
        >
          <v-subheader
            class="white--text display-1"
          >
            Excluindo grupo de conta...
          </v-subheader>
        </v-progress-linear>
      </v-card>
    </v-dialog>

    <!-- Formulario de cadastro -->
    <v-dialog
      v-model="formDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="formDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ editingGroup.id > 0 ? "Editar grupo" : "Novo grupo" }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="green" style="padding: 5px 25px !important"
              dark
              text
              @click="saveGroup"
            >
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-list
          three-line
          subheader
        >
          <v-subheader>Dados</v-subheader>
          <v-row no-gutters class="mx-4">
            <v-col cols="12" sm="3" class="px-2">
              <v-text-field
                label="Conta"
                v-model="editingGroup.account"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3" class="px-2"
            >
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="editingGroup.expire_date"
                    label="Data validade"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editingGroup.expire_date"
                  no-title
                  @input="menu1 = false"
                  :show-current="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              offset-sm="5"
              cols="12"
              sm="1" class="px-2"
            >
              <v-switch
                :false-value="0"
                :true-value="1"
                label="Ativo?"
                v-model="editingGroup.active"
                class="mx-2"
              />
            </v-col>
          </v-row>
        </v-list>
        <v-divider></v-divider>

        <v-subheader>Detalhes</v-subheader>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="CNPJ"
                v-model="editingGroup.detail.cnpj"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="IE"
                v-model="editingGroup.detail.ie"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Nome da empresa"
                v-model="editingGroup.detail.company_name"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Razão social"
                v-model="editingGroup.detail.razao_social"
              />
          </v-col>
        </v-row>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Email administrador"
                v-model="editingGroup.detail.email_adm"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Telefone"
                v-model="editingGroup.detail.phone_number1"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Telefone 2"
                v-model="editingGroup.detail.phone_number2"
              />
          </v-col>
        </v-row>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Endereço"
                v-model="editingGroup.detail.address"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Bairro"
                v-model="editingGroup.detail.district"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Cidade"
                v-model="editingGroup.detail.city"
              />
          </v-col>
          <v-col cols="12" sm="1" class="px-2">
            <v-select
                color="secondary"
                item-color="secondary"
                label="Estado"
                :items="statesList"
                v-model="editingGroup.detail.state"
                item-text="name"
                item-value="id"
              >
            </v-select>
          </v-col>
          <v-col cols="12" sm="1" class="px-2">
            <v-text-field
                label="País"
                v-model="editingGroup.detail.country"
              />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-subheader>Dados do responsável</v-subheader>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Nome"
                v-model="editingGroup.detail.name_rep"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="CPF"
                v-model="editingGroup.detail.cpf_rep"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Email"
                v-model="editingGroup.detail.email_rep"
              />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-img
              :src="editingGroup.detail.layout"
              aspect-ratio="1.7" contain />
            </v-col>
        </v-row>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import axios from 'axios';
import { baseApiUrl } from '@/global';
import moment from 'moment';
import { states } from '@/code/util/constants';

axios.defaults.headers.common['Content-Type'] = 'application/json';

export default {

  data() {
    return {
      accountGroups: [],
      selectedGroup: { detail: {} },
      editingGroup: { detail: {} },
      confirmDelete: false,
      successDelete: false,
      progressDelete: false,
      filterText: "",
      formDialog: false,
      loading: false,
      menu1: false,
      statesList: states,
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
          filterable: false
        },
        {
          text: 'Descrição',
          align: 'start',
          sortable: true,
          value: 'account',
        },
        {
          text: 'Cidade',
          align: 'start',
          sortable: true,
          value: 'detail.city'
        },
        {
          text: 'Ativo?',
          align: 'start',
          sortable: true,
          value: 'active',
          filterable: false
        },
        {
          text: 'Data modificação',
          align: 'start',
          sortable: true,
          value: 'modified',
          filterable: false
        },
        { text: 'Ações', value: 'actions', sortable: false }
      ]
    }
  },
  mounted() {
    this.loadAccountGroups();
  },

  methods: {
    loadAccountGroups: async function() {
      this.loading = true;
      this.accountGroups = [];
      var accountGroups = await axios.get(`${baseApiUrl}accounts?all=1&detail=1&type=group`);
      if (accountGroups) {
        accountGroups.data.data.forEach(group => {
          this.accountGroups.push(this.createNewGroup(group));
        });
      }
      this.loading = false;
    },
    createNewGroup: function(group) {
      var g = {
        id: group.id,
        account: group.account,
        license: group.license,
        active: group.active,
        parent_id: group.parent_id,
        expire_date: group.expire_date,
        created: group.created,
        modified: group.modified,
        linx_portal: group.linx_portal,
        linx_cnpj: group.linx_cnpj,
        linx_empresaid: group.linx_empresaid,
        printnode_id: group.printnode_id
      };
      g.detail = {};
      if (group.detail) {
        g.detail = {
          id: group.detail.id,
          id_accounts: group.detail.id_accounts,
          cnpj: group.detail.cnpj,
          ie: group.detail.ie,
          company_name: group.detail.company_name,
          razao_social: group.detail.razao_social,
          name_rep: group.detail.name_rep,
          cpf_rep: group.detail.cpf_rep,
          email_rep: group.detail.email_rep,
          email_adm: group.detail.email_adm,
          phone_number1: group.detail.phone_number1,
          phone_number2: group.detail.phone_number2,
          address: group.detail.address,
          district: group.detail.district,
          city: group.detail.city,
          state: group.detail.state,
          country: group.detail.country,
          layout: group.detail.layout,
          modified: group.detail.modified,
          created: group.detail.created,
        }
      };

      return g;
    },
    getFormattedDate: function(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    getFormattedDateTime: function(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },
    newGroup: function() {
      this.editingGroup = this.createNewGroup({ id: 0, active: 1, detail: { } });
      this.formDialog = true;
    },
    editGroup: function(group) {
      this.editingGroup = this.createNewGroup(group);
      this.formDialog = true;
    },
    confirmDeleteGroup: function(group) {
      this.selectedGroup = group;
      this.confirmDelete = true;
    },
    deleteGroup: async function() {
      if (this.selectedGroup) {
        this.confirmDelete = false;
        this.progressDelete = true;
        var response = await axios.delete(`${baseApiUrl}accounts/${this.selectedGroup.id}`);
        this.progressDelete = false;
        if (response && response.status >= 200 && response.status < 300) {
          this.accountGroups.splice(this.accountGroups.indexOf(this.selectedGroup), 1);
          this.successDelete = true;
          this.selectedGroup = { detail: {} };
        }
      }
    },
    saveGroup: async function() {
      if (this.editingGroup) {
        var toSave = {
          account: this.editingGroup.account,
          active: this.editingGroup.active,
          expire_date: this.editingGroup.expire_date,
          type: 'group',
          detail: {
            cnpj: this.editingGroup.detail.cnpj,
            ie: this.editingGroup.detail.ie,
            company_name: this.editingGroup.detail.company_name,
            razao_social: this.editingGroup.detail.razao_social,
            name_rep: this.editingGroup.detail.name_rep,
            cpf_rep: this.editingGroup.detail.cpf_rep,
            email_rep: this.editingGroup.detail.email_rep,
            email_adm: this.editingGroup.detail.email_adm,
            phone_number1: this.editingGroup.detail.phone_number1,
            phone_number2: this.editingGroup.detail.phone_number2,
            address: this.editingGroup.detail.address,
            district: this.editingGroup.detail.district,
            city: this.editingGroup.detail.city,
            state: this.editingGroup.detail.state,
            country: this.editingGroup.detail.country,
          }
        };
        var response = null;
        if (this.editingGroup.id > 0) {
          response = await axios.put(`${baseApiUrl}accounts/${this.editingGroup.id}`, toSave);
        } else {
          response = await axios.post(`${baseApiUrl}accounts`, toSave);
        }

        if (response && response.status >= 200 && response.status < 300) {
          if (this.editingGroup.id > 0) {
            var that = this;
            var itemsById = this.accountGroups.filter((group) => group.id === that.editingGroup.id);
            if (itemsById && itemsById.length > 0) {
              this.accountGroups.splice(this.accountGroups.indexOf(itemsById[0]), 1);
            }
          }
          this.accountGroups.push(this.createNewGroup(response.data.data));
          this.formDialog = false
        }
      }
    }
  }

}
</script>